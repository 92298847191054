// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  //RaboBank
  --base: #333333;
  --base-light: #7e888d;
  --main: #002899;
  --btn-main: #FF6600;
  --menu-text: #FFF;
  --menu-bg: #002899;
  --static-elm-hover: #FFF0E7;
  --elm-fill: #B2BEE0;
  --background-primary: #FFF;
  --background-secondary: #F5F5F5;
  --disabled: #A5A5A5;
  --delimiter: #A1A1A1;
  --error: #E40202;
  --highlight: #FF6600;
  --icon-primary: #002899;
  --active-section: #F1F6F9;
  --chart-primary: #002899;
  --chart-fill-1: #B2BEE0;
  --chart-fill-2: #FF9438;
  --chart-fill-3: #E40202;
  --chart-fill-4: #FFCB44;
  --chart-trading-currency-buy: #002899;
  --chart-trading-currency-sell: #00289966;
  --chart-contra-currency-sell: #5D5E6080;
  --chart-contra-currency-buy: #5D5E60D9;
  --chart-bubble-positive: #0072FF;
  --chart-bubble-negative: #F44C4C;
  --success: #18D25C;
  --header: #002899;
  --warn: #FF9438;

  --font-light: Myriad Pro Light;
  --font-regular: Myriad Pro Regular;

  --button-border-radius: 8px / 25px;
  --instrument-button-border-radius: 4px;
  --input-border-radius: 8px;
}

:focus {
  outline: none;
}
